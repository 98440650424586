import React from "react";
import { useTranslation } from "react-i18next";
import  { useEffect,useState} from 'react'
import cookies from 'js-cookie'
// import en from './img/EN.svg'
// import ar from './img/ES.svg'

const languages = [
  {
    code: 'en',
    name: 'English',
    translation: {
      toggle: 'Toggle',
      on: 'EN',
      off: 'AR',
    },
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl', 
    translation: {
      toggle: 'Toggle',
      on: 'AR',
      off: 'EN',
    },
  },

]


function LanguageSwitcher() {
  const [checked, setChecked] = useState(false);

  const { i18n } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en' ;
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage,checked])
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const { t } = useTranslation();
  const handleToggle = () => {
    setChecked(!checked);
    if (checked) {
      i18n.changeLanguage("en");
    }
    else {
      i18n.changeLanguage("ar");

    }
  };
  return (
    <>
  {/* <select
      id="languageSwitcher"
      style={{backgroundColor:"transparent",border:"none",fontSize:"22px", color:"#2A3657"}}
        value={i18n.language}
                onChange={(e) =>
          i18n.changeLanguage(e.target.value)
        }
      >
        <option       style={{ color:"#2A3657"}} value="en">

          EN
          </option>
        <option     style={{ color:"#2A3657"}}    value="ar">

AR</option>
      </select> */}
  {/* <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="toggleSwitch"
        checked={checked}
onChange={handleToggle}
      />
      <label className="form-check-label" htmlFor="toggleSwitch">
      {checked ? t('EN') : t('AR')}
      </label>
    </div> */}

{ currentLanguageCode=='en' &&
<p className="" onClick={(e) =>
          i18n.changeLanguage("ar")}
>
      عربى
    </p>
}
    
{ currentLanguageCode=='ar' &&
<p onClick={(e) =>
          i18n.changeLanguage("en")}
>
EN
    </p>
}
 





    </>
    
  );
}

export default LanguageSwitcher;