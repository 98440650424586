import { useState, useEffect } from 'react'

import { Link } from 'react-router-dom';
import { AiOutlineCalendar } from "react-icons/ai";
import { GiSailboat } from 'react-icons/gi';


import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './header.css'
import {MdLanguage} from "react-icons/md"

import LanguageSwitcher2 from "./../LanguageSwitcher2";

import LanguageSwitcher from "./../LanguageSwitcher";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: " 80%",
  height: " 80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',

};


const Header = () => {
  const { t } = useTranslation();//

  let [val, setVal] = useState("EN");
  let [checkNav, setCheckNav] = useState(false)

  var [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    document.querySelectorAll('.ooo').forEach(function (el) {

      el.addEventListener('click', function () {
        document.querySelector('.dropdown-toggle-lang').textContent = el.textContent;
        setVal(el.textContent)

        document.querySelector('#vote').value = el.textContent;
      });

    });

    const navE1 = document.querySelector('.navbar');
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 200) {
        setCheckNav(true)

      }
      else if (window.scrollY < 200) {
        setCheckNav(false)

      }
    })

  }, []);



  return (
    <>
      {/* {checkNav === false &&

        <Navbar className="navbar navbar-expand-lg navbar-light navbar-scrolled fixed-top bgnav" expand="lg">
          <Container className='d-flex justify-content-between'>
            <div className='d-flex d-block    align-items-center'>
<Link to="/home">
                <img src={require("./../images/logo.png")} alt="Bootstrap " width="60" height="54" />


              </Link> 
              <Link to="/home" className="mb-3">
              <span class="lh-1 mb-2" style={{ color: "#2A3657", fontSize:"18px", textDecorationStyle:"none" }} className=''>{t("Horus")}  </span> 

              <span class="lh-1" style={{ color: "#2A3657", fontSize:"12px", textDecorationStyle:"none" }} className=''> {t("Specialist Manpower")} </span> 
             <p class="lh-1" style={{ color: "#2A3657", fontSize:"12px", textDecorationStyle:"none" }} > {t("Recruitment Co.Licence No. 64")}
</p>
              </Link>
              </div>

          
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
            
            <div>
              <Navbar.Collapse id="navbarScroll">
                <Nav className="me-auto">

                  <li class="nav-item ">

                      <a class="nav-link fs-6  color fw-bolder px-3 pt-3  " href="#plans"><h4  className='coller' style={{ color: "white" }}> {t("Services")}</h4> </a>
                    

                  </li>
                  <Link to="/contact">

                  <li class="nav-item   ">
                      <a class="nav-link fs-6 color fw-bolder px-3 pt-3 " href="#"> <h4 className='coller' style={{ color: "white" }}>{t("contact")} </h4> </a>


                  </li>

</Link>
                  <li class="nav-item   ">
                    <a className=" d-flex flex-row nav-link px-3   coller" href="#." style={{ color: "white" }}>
                <LanguageSwitcher2 />

                    </a>

                  </li>
                  
                  
                </Nav>
              </Navbar.Collapse>
            </div>

          </Container>
        </Navbar>

      } */}


<Navbar className="navbar navbar-expand-lg navbar-light navbar-scrolled fixed-top bgnav2 px-md-5" expand="lg">
        <Container className='d-flex justify-content-between'>




          <div className='d-flex d-block col-10 align-items-center'>
            <Link to="/home">
              <img src={require("./../images/logo.png")} alt="Bootstrap " width="62" height="60" />


            </Link>
            <Link to="/home" className=" ">

              <p class="" style={{ color: "#2A3657", fontSize: "18px", textDecorationStyle: "none" }} className=''>   
                          <span class="fw-bold" style={{ color: "#2A3657", fontSize: "21px", textDecorationStyle: "none" }} className='fw-bolder'>{t("Horus")}  </span>
{t("Specialist Manpower")} </p>
              {/* <p class="lh-1 fw-bolder" style={{ color: "#2A3657", fontSize: "10px", textDecorationStyle: "none" }} > {t("Recruitment Co.Licence No. 64")}</p> */}
            </Link>
          </div>

          <Navbar.Toggle aria-controls="basic-navbar-nav" className='col-2' />
        

        </Container>
        <div>
            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto d-flex align-items-md-center">

                <li class="nav-item ">
                <Link to="/Home">

                  <a class="nav-link fs-6 fw-bolder px-3  color  " style={{width:"max-content"}} href="#plans"><h4 className="coller">{t("Home")}</h4> </a>
                  </Link>

                </li>

                <li class="nav-item   ">
                  <Link to="/contact">

                    <a class="nav-link color coller fs-6 fw-bolder px-3  "style={{width:"max-content"}}  href="#contact"> <h4 className="coller">{t("contact")} </h4> </a>

                  </Link>

                </li>

                <li class="nav-item   ">
                  <a className="nav-link px-3 d-flex flex-row color fs-6 fw-bolder coller " href="#." style={{ fontSize: "22px" }}>
                    <MdLanguage className='mt-1' size={24} /> <LanguageSwitcher />

                  </a>

                </li>



              </Nav>
            </Navbar.Collapse>
          </div>
      </Navbar>






    </>
  );
};

export default Header;


