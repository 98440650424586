import { React, useState } from "react";
import "./../../App.css";
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaRegLightbulb } from "react-icons/fa";
import { BsFillLightningFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BiMessageAltDetail } from "react-icons/bi";

import { HiEye } from "react-icons/hi";

import { FaFax } from "react-icons/fa";
import PureCounter from "@srexi/purecounterjs";
import cookies from "js-cookie";
import Header from "./../Header/header";

const Home = () => {
  const { t } = useTranslation();
  const [key, setKey] = useState("Home");
  const [Lang, setLang] = useState("en");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const pure = new PureCounter();
  var settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: -1,
    initialSlide: 0,
    variableWidth: true,
    nextArrow: false,
    prevArrow: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          pauseOnHover: true,
          pauseOnFocus: true,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: -1,
          autoplay: false,
          infinite: true,
          dots: false,
          pauseOnHover: true,
          pauseOnFocus: true,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: -1,
          autoplay: false,
          infinite: true,
          pauseOnHover: true,
          pauseOnFocus: true,
          nextArrow: true,
          prevArrow: true,
        },
      },
    ],
  };

  var settings2 = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    nextArrow: false,
    prevArrow: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
    pauseOnFocus: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          pauseOnHover: true,
          pauseOnFocus: true,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          autoplay: false,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          pauseOnHover: true,
          pauseOnFocus: true,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplay: false,
          slidesToScroll: 1,
          infinite: true,
          pauseOnHover: true,
          pauseOnFocus: true,
          nextArrow: true,
          prevArrow: true,

        },
      },
    ],
  };

  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  window.addEventListener("scroll", reveal);
  useEffect(() => {
    setLang(cookies.get("i18next"));
    console.log(Lang);
  });


  useEffect(() => {
    function handleResize() {
      setIsSmallScreen(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Header />
      <section  className={`home py-5 ${Lang=="en"?"clip2":"clip1"}`} id="header">
    


    <div class="container my-3 py-5">
<div class="row d-flex py-md-5 justify-content-center align-items-center">
<div class="col-md-8 py-5 text-center d-flex justify-content-center align-items-center ">
            {/* <h3 class="section-title mb-3 text-white" data-aos="fade-up" data-aos-delay="">     <span style={{fontSize:""}}>{t("Horus")}</span>   {t("Specialist Manpower Recruitment")} </h3> */}
            {/* <h1 class="section-title mb-3" data-aos="fade-up" data-aos-delay="">{t("WE ARE")}</h1> */}

            <h2 style={{fontSize:"1.8rem"}} class=" col-10 text-white fs-md-5 " data-aos="fade-up" data-aos-delay="100">
            {t(
                      "One of the leading global recruitment agencies - approved by the government - with good experience in Egypt"
                    )}
                    <br />
                    {t("(established in 1992 - commercial license No. 64)")}
</h2>
          </div>





</div>


</div>
  </section>

  <div class="site-section cta-big-image" id="about-section">
      <div class="container">
        {/* <div class="row mb-5 justify-content-center">
          <div class="col-md-8 text-center">
            <h2 class="section-title mb-3" data-aos="fade-up" data-aos-delay="">About Us</h2>
            <h6 class="lead text-black" data-aos="fade-up" data-aos-delay="100">
              one of the leading - government approved – well experienced international 
recruitment agencies in Egypt (Established in 1992 -Recruiting license No. 64)
</h6>
          </div>
        </div> */}
        <div class="row  d-flex justify-content-between">
          <div class="col-lg-6 mb-5 " data-aos="fade-up" data-aos-delay="">
            <div class="circle-bg">
            <img src={require("./../images/img_2.jpg")} alt="Free Website Template by Free-Template.co" class="img-fluid"/>
            </div>


            
          </div>


          <div class="col-lg-5 mb-5 ml-auto d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="100">
          <div>  
            <h1 class="text- mb-5 section-title ">{t("About Us")}</h1>
            </div>
            
                <div  className="col-md-12 col-11">
                  
            <h4 class="text-black  mb-4">
              {t("We are Horus Specialist Manpower Recruitment, one of the leading government approved – well experienced international recruitment agencies in Egypt   (Established in 1992 -Recruiting license No. 64)")}
</h4>

<h4 class="text-black">
{t("Which works in the field of human resources (nominating, testing and selecting trained Egyptian candidates to work abroad according to the needs and requirements of our clients abroad according to scientific and practical mechanisms).")}</h4>

                  </div>  

            
          </div>

        </div>    
        
      </div>  
    </div>

  <section class="information">
    <div class="container-fluid ">
      <div class="row text-light">
      {/* text-center */}
        <div class="col-lg-6  px-5  pt-5" data-aos="zoom-in">
<div className="d-flex"> 
{/* justify-content-center             */}
<HiEye class="  fas fa-3x p-2" color="#58a8c5" size={50} /> 
<span className="" style={{fontSize:"2rem"}} >

{t("Vision")}
</span>
</div>




          <p class="para-light mt-4 lh-base">
           - {t(
"Providing highly competent trained human resources that are suitable for the international job market"    
        )}
            <br />
           - {t("Dealing with our clients abroad as strategic partners and providing them with our expertise in the field of human resources")}
            <br /> <br />
            <br />
          </p>
        </div>
        <div class="col-lg-6 pt-5  px-5  " data-aos="zoom-in">


<div className="d-flex " style={{backgroundColor:"#212D37"}}>             
<BiMessageAltDetail class="  fas fa-3x p-2" color="#58a8c5" size={50} /> 
<span className="" style={{fontSize:"2rem"}} >

{t("Mission")}
</span>
</div>

          {/* <h4 class="py-3">99% Internet Uptime</h4> */}
          <p class="para-light mt-4 lh-base">
           - {t("Testing and screening human resources for our clients abroad")}
            <br />
           - {t(
"Conducting training operations for professional ,Providing guidance"                )}
            <br />
-{t("Providing our clients abroad with all information and  Completing the procedures for contractors")}
          </p>
        </div>
    
      </div>
    </div>
  </section>

      
    

      <section class="plans d-flex align-items-center py-5" id="plans">
        <div class="container text-light">
          <div class="text-center pb-4 container reveal fade-bottom ">
            <h2>
              {t("OUR SERVICES")} {t("THAT WE OFFER")}
            </h2>
            <p class="py-2"> </p>
          </div>
          <div
            class="row gy-4 d-flex justify-content-center"
            data-aos="zoom-in"
          >
            <div class="col-lg-6 reveal container fade-bottom">
              <div class="card bg-transparent px-4 ">
                <h4 class="py-2">{t("First: for our clients abroad")} </h4>

                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Coordinating with clients abroad on the cooperation mechanism to complete the recruitment process"
                    )}
                  </p>
                </div>
                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Providing all logistical services to delegates of the recruitment committees While they are in Egypt"
                    )}
                  </p>
                </div>
                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Commitment on the part of our company to send the candidates at the Specified time for their travel"
                    )}
                  </p>
                </div>

                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Sending the arrival date for the candidates to their work sites before sufficient time"
                    )}
                  </p>
                </div>
                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Follow-up with your esteemed departments to know the level of service provided by our company"
                    )}
                  </p>
                </div>

                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Providing the required specializations according to the needs of the client abroad in accordance with the functional and professional conditions that are commensurate with the nature of the work and activity of the recruiting entity and are consistent with the regulations and laws of the contracting country"
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 my-3 my-lg-0 d-flex align-items-center container reveal fade-bottom">
              <div class="card bg-transparent px-4 h-100  ">
                <h4 class="py-2">{t("Second: for our clients in Egypt")} </h4>

                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Achieving all procedures for obtaining their Visas and attest their Contracts and all documents"
                    )}
                  </p>
                </div>
                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Providing assistance to the joining families of those selected personnel when they get their visas"
                    )}{" "}
                  </p>
                </div>
                <div class="block d-flex align-items-center">
                  <p class="pe-3">
                    <i class="far fa-check-circle fa-1x"></i>
                  </p>
                  <p class="pe-3">
                    {t(
                      "Explaining all work conditions and nature, along with directing and providing instructions to the candidates before travelling to their works to enable them to adapt to the work conditions , law , customs and traditions of the contracting country"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="work d-flex align-items-center py-5">
        <div class="container-fluid text-light">
          <div class="row">
            <div
              class="col-lg-6 d-flex align-items-center reveal container fade-right"
              data-aos="fade-right"
            >
              <img
                class="img-fluid p-2"
                src={require("./../images/work.jpg")}
                alt="work"
              />
            </div>
            <div
              class="col-lg-5 d-flex align-items-center px-4 py-3 reveal container fade-right"
              data-aos=""
            >
              
                <div class="containe" data-aos="fade-up">
              
                  {/* <span
                    className=" 
                
                "
                    style={{ color: "#58a8c5", fontSize: "35px" }}
                  >
                    {" "}
                    {t("Horus")}
                  </span> */}


                  <div class="row g-5 d-flex justify-content-center">
                    <div class="col-6 reveal fade-bottom">
                      <i class="fas fa-briefcase fa-2x text-start"></i>
                      <h2
                        class="purecounter"
                        data-purecounter-start="0"
                        data-purecounter-end="32"
                        data-purecounter-duration="3"
                      >
                        1
                      </h2>
                      <p style={{fontSize:"1.2rem"}}>{t("Experience")}</p>
                    </div>
                    <div class="col-6 reveal fade-bottom">
                      <i class="fas fa-award fa-2x"></i>
                      <h2
                        class="purecounter"
                        data-purecounter-start="0"
                        data-purecounter-end="150"
                        data-purecounter-duration="3"
                      >
                        1
                      </h2>
                      <p style={{fontSize:"1.2rem"}}>{t("AWARDS")}</p>
                    </div>
                    <div class="col-6 reveal fade-bottom">
                      <i class="fas fa-users fa-2x"></i>
                      <h2
                        class="purecounter"
                        data-purecounter-start="0"
                        data-purecounter-end="1255"
                        data-purecounter-duration="3"
                      >
                        1
                      </h2>
                      <p style={{fontSize:"1.2rem"}}>{t("CUSTOMER ACTIVE")}</p>
                    </div>
                    <div class="col-6 reveal fade-bottom">
                      <i class="fas fa-clock fa-2x"></i>
                      <h2
                        class="purecounter"
                        data-purecounter-start="0"
                        data-purecounter-end="1157"
                        data-purecounter-duration="3"
                      >
                        1
                      </h2>
                      <p style={{fontSize:"1.2rem"}}>{t("GOOD REVIEWS")}</p>
                    </div>
                  </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>








      <section className="container my-5 py-5 " style={{ backgroundColor: "white" }}>
        <h1 className="d-flex justify-content-center py-5">{t("Certification")}</h1>
        {isSmallScreen ? (
       <div className="row px-5  d-flex justify-content-evenly">
       <div class="cardimg my-5 col-md-2 col-sm-6 col-12" ><img src={require("./../images/1.png")} />
       </div>

       <div class="cardimg my-5 col-md-2 col-sm-6 col-12" ><img src={require("./../images/3.jpg")} />
       </div>
       <div class="cardimg my-5  col-md-2 col-sm-6 col-12" ><img src={require("./../images/2.jpg")} />
       </div>
       <div class="cardimg my-5 col-md-2 col-sm-6 col-12" ><img src={require("./../images/4.jpg")} />
       </div>
       <div class="cardimg my-5 col-md-2 col-sm-6 col-12" ><img src={require("./../images/5.jpg")} />
       </div>
     </div>
      ) : (
        <div className="row px-5 d-flex justify-content-evenly">
        <div class="cardimg col-md-2 col-sm-6 col-12" style={{ transform: "rotate(-9.8105428493614397deg)" }}><img src={require("./../images/1.png")} />
          {/* <h2>Card Title</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum veritatis eaque necessitatibus, explicabo vero hic,.</p> */}
        </div>

        <div class="cardimg col-md-2 col-sm-6 col-12" style={{ transform: "rotate(-7.8105428493614397deg)" }}><img src={require("./../images/3.jpg")} />
          {/* <h2>Card Title</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum veritatis eaque necessitatibus, explicabo.</p> */}
        </div>
        <div class="cardimg mt-5 col-md-2 col-sm-6 col-12" ><img src={require("./../images/2.jpg")} />
          {/* <h2>Card Title</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum veritatis eaque necessitatibus, explicabo.</p> */}
        </div>
        <div class="cardimg col-md-2 col-sm-6 col-12" style={{ transform: "rotate(7.8105428493614397deg)" }}><img src={require("./../images/4.jpg")} />
          {/* <h2>Card Title</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum veritatis eaque necessitatibus, explicabo.</p> */}
        </div>
        <div class="cardimg col-md-2 col-sm-6 col-12" style={{ transform: "rotate(9.8105428493614397deg)" }}><img src={require("./../images/5.jpg")} />
          {/* <h2>Card Title</h2> */}
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum veritatis eaque necessitatibus, explicabo.</p> */}
        </div>
      </div>
      )}
      </section>




    

      {Lang == "en" && (
        <div class="slider-1 testimonial text-light ">
          <div>
            <div class="text-center mb-5">
              <h1> OUR CLIENTS</h1>
            </div>
          </div>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-5 d-flex justify-content-md-center"
          >
            <Tab eventKey="Home" title="Medical">
              <div class="container">
                <h2
                  className="d-flex justify-content-center ms-4 ms-md-0"
                  style={{ color: "" }}
                >
                  In the medical field, maintenance services and hospital
                  operation
                </h2>

                <div class="row px-3" data-aos="zoom-in">
                  <div class="col-lg-12">
                    <div className="row slider-pic">
                      
                      <Slider {...settings2}>
                        
                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/3333.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Military Hospitals</h6>
                                <p>in KSA</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/OIP.jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Security Forces Hospital Program</h6>
                                <p>in KSA - Riyadh</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/OIP (1).jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>King Khalid University Hospital</h6>
                                <p>in KSA - Riyadh</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/111111111111.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>King Saud University </h6>
                                <p>in KSA - Riyadh</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/ks.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Scientifoc & medical equipment </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/dallah.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>ALAhmady Hospital-Dallah </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/AD.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Al Dukhail </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/ff.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Future First Medical Complex </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/DouratOkaz.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Dourat Okaz </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/El- Shakreen.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>El- Shakreen Center for Dentist</h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Al Sheffa.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Al Sheffa Pharmacy </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Al Amjad.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Al Amjad Pharmacy </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/El-Khonaini.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>El-Khonaini Pharmacy </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/kfary.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>El-Kafary Pharmacy </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/tiba.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Tibba Hospital </h6>
                                <p>in Kuwait </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/daman.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Daman Hospital </h6>
                                <p>in Kuwait </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Alorf Hospital.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Elorf Hospital </h6>
                                <p>in Kuwait </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/seef.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Elseef Hospital </h6>
                                <p>in Kuwait </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/salam.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>El-Salam Hospital </h6>
                                <p>in Kuwait </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/alia.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Alia Hospital </h6>
                                <p>in Kuwait </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/midan.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6> El- Median Medical Center for Dentist </h6>
                                <p>in Kuwait </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/reef.jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Al-Reef International Hospital </h6>
                                <p>in U.A.E </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Horizon.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Horizon Hospital </h6>
                                <p>in U.A.E </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/drnutrition-logo-rtl.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Dr.Nutrition Medical Center </h6>
                                <p>in U.A.E </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/emadi.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6> El Emadi Hospital </h6>
                                <p>in QATAR </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Shefa.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6> El Shefa Hospital </h6>
                                <p>in QATAR </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/15345886464293.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6> Global Hospital </h6>
                                <p>in QATAR </p>
                              </div>
                            </div>
                          </div>
                        </figure>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </Tab>
            <Tab eventKey="profile" title="Trading & Construction">
              <div class="container">
                <h2
                  className="d-flex justify-content-center ms-4 ms-md-0"
                  style={{ color: "" }}
                >
                  Trading / Commercials / Engineering /Construction/ Maintenance
                  and Services companies
                </h2>

                <div class="row px-3" data-aos="zoom-in">
                  <div class="col-lg-12">
                    <div className="row slider-pic">
                      <Slider {...settings2}>
                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Aecom.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Aecom Company </h6>
                                <p>in KSA</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Systra.jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Systra </h6>
                                <p>in KSA</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  size={22}
                                  src={require("./../images/rajah.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Soliman El-Raghy Company</h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/5555.bmp")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Jabal Omar Company </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Al-Khonaini.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Al-Khonaini Group </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/sod.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Saudi Industrial Development Co. SIDC</h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/zamil.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>ELZamil Factory</h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </Tab>
            <Tab eventKey="contact" title="Tourism & Education">
              <div class="container">
                <h2
                  className="d-flex justify-content-center ms-4 ms-md-0"
                  style={{ color: "" }}
                >
                  {" "}
                  Tourism, hotels , education and restaurants{" "}
                </h2>

                <div class="row px-3" data-aos="zoom-in">
                  <div class="col-lg-12">
                    <div className="row slider-pic">
                      <Slider {...settings2}>
                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/3nod.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Elonoud Hotel </h6>
                                <p>in KSA -Riyadh</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/tohama.jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Thama Agency</h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/1236710_399435510158461_457828220_n.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>El-Mossem</h6>{" "}
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/7.webp")}
                                  alt="testimonial"
                                />
                              </div>

                              <div class="ms-3 pt-2 col-7">
                                <h6>Safin Restaurant</h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/ghad.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>El-Ghad Colleges </h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/103.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>El-Aksa Schools</h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/download.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>Abha National Schools</h6>
                                <p>in KSA </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                  

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/eeee.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7">
                                <h6>- Emar For Aluminum</h6>{" "}
                              </div>
                            </div>
                          </div>
                        </figure>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </Tab>
          </Tabs>
        </div>
      )}
      {Lang == "ar" && (
        <div class="slider-1 testimonial text-light ">
          <div class=" mb-2">
            <div class="text-center w-lg-75 m-auto pb-4">
              <h1>عملائنا</h1>
            </div>
          </div>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-5 d-flex justify-content-md-center"
          >
            <Tab eventKey="Home" title="المجال الطبي ">
              <div class="container">
                <h2
                  className="d-flex justify-content-center ms-4 ms-md-0 mx-4"
                  style={{ color: "" }}
                >
                  في المجال الطبي وخدمات الصيانة وتشغيل المستشفيات{" "}
                </h2>

                <div class="row px-3" data-aos="zoom-in">
                  <div class="col-lg-12">
                    <div className="row slider-pic">
                      <Slider {...settings}>
                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/3333.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفيات وزارة الدفاع السعودى </h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/OIP.jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">وزارة الداخلية السعودية</h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/ks.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">دار المعدات الطبية والعلمية </h6>
                                <p>السعوديه </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/dallah.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">داله </h6>
                                <p>بالسعوديه </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/AD.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">الدخيل الطبى </h6>
                                <p>السعودية </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/ff.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">المستقبل الطبي الاول </h6>
                                <p>السعوديه</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/DouratOkaz.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">درة عكاظ </h6>
                                <p>السعودية </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/El- Shakreen.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">الشاكرين لطب الاسنان </h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Al Sheffa.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">الشفاء الطبي </h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Al Amjad.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">الامجاد</h6>
                                <p>السعودية </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/El-Khonaini.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">الخنينى</h6>
                                <p>السعودية </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/kfary.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">القفارى </h6>
                                <p>السعودية </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/tiba.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفي طيبة </h6>
                                <p>الكويت </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/daman.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفي ضمان </h6>
                                <p>الكويت </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Alorf Hospital.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفي العرف </h6>
                                <p>الكويت</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/seef.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفي السيف </h6>
                                <p>الكويت </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/salam.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفي السلام الدولى </h6>
                                <p>الكويت </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/alia.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفي عالية الدولى </h6>
                                <p>السلام الدولى </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/midan.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">الميدان لطب الاسنان</h6>
                                <p>الكويت </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/reef.jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفي الريف الدولي </h6>
                                <p>الامارات</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Horizon.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مستشفي سمارت </h6>
                                <p>الامارات </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/drnutrition-logo-rtl.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">دكتور نيوترشن الطبى</h6>
                                <p>الامارات </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/emadi.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR"> مستشفي العمادي الدولي </h6>
                                <p>قطر </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Shefa.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR"> مستشفي الشفا </h6>
                                <p>قطر </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/15345886464293.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR"> مستشفي جلوبال </h6>
                                <p>قطر</p>
                              </div>
                            </div>
                          </div>
                        </figure>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </Tab>
            <Tab eventKey="profile" title="المجال التجاري  والمقاولات ">
              <div class="container">
                <h2
                  className="d-flex justify-content-center ms-4 ms-md-0 mx-4"
                  style={{ color: "" }}
                >
                  في المجال التجاري والمقاولات والسياحة والمجالات الاخرى
                </h2>

                <div class="row px-3" data-aos="zoom-in">
                  <div class="col-lg-12">
                    <div className="row slider-pic">
                      <Slider {...settings}>
                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Aecom.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">شركة أيكوم العربية المحدودة </h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Systra.jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">سيسترا </h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  size={22}
                                  src={require("./../images/rajah.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">شركة سليمان عبد العزيز الراجحى</h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/5555.bmp")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR"> شركة جبل عمر للتطوير </h6>
                                <p>السعودية </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/Al-Khonaini.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مجموعة الخنيني </h6>
                                <p>السعودية </p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/sod.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">الشركة السعودية للتنمية الصناعية صدق </h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/zamil.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مصنع الزامل</h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </Tab>
            <Tab eventKey="contact" title="المجال التعليمى  و السياحة">
              <div class="container">
                <h2
                  className="d-flex justify-content-center ms-4 ms-md-0 mx-4"
                  style={{ color: "" }}
                >
                  عملاؤنا في المجال التعليمى والسياحى{" "}
                </h2>

                <div class="row px-3" data-aos="zoom-in">
                  <div class="col-lg-12">
                    <div className="row slider-pic">
                      <Slider {...settings}>
                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/3nod.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">فندق العنود </h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/tohama.jpeg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">تهامة للسفر والسياحة </h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/1236710_399435510158461_457828220_n.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">الموسم للسفر والسياحة</h6>{" "}
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/7.webp")}
                                  alt="testimonial"
                                />
                              </div>

                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مطعم سفين</h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/ghad.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">
                                  كليات الغدالدولية للعلوم الصحية التطبيقية{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/103.jpg")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مدارس الاقصى بجدة</h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/download.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR">مدارس ابها الاهلية</h6>
                                <p>السعودية</p>
                              </div>
                            </div>
                          </div>
                        </figure>

                        <figure
                          class="image-block p-3"
                          style={{ width: "22rem" }}
                        >
                          <div class="testimonial-card p-4">
                            <div class="d-flex pt-4 row justify-content-between">
                              <div className="col-4">
                                <img
                                  class="avatar"
                                  src={require("./../images/eeee.png")}
                                  alt="testimonial"
                                />
                              </div>
                              <div class="ms-3 pt-2 col-7 d-flex flex-column  align-items-end">
                                <h6 className="fontAR"> شركة إعمار لأعمال الالمنيوم</h6>{" "}
                              </div>
                            </div>
                          </div>
                        </figure>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </Tab>
          </Tabs>
        </div>
      )}

      <section class="location text-light py-5 " id="contact">
        <div class="container" data-aos="zoom-in">
          <div class="row d-flex justify-content-around ">
            <div class="col-lg-3 py-lg-0 py-2 d-flex align-items-center justify-content-lg-center reveal container fade-right">
              <div class="p-2">
                <i class="far fa-map fa-3x"></i>
              </div>
              <div class="ms-2">
                <h5>{t("ADDRESS")}</h5>
                <p style={{fontSize:"1rem"}}>{t("5 Al-Golf Street, Maadi, Cairo, Egypt")}</p>
                <p style={{fontSize:"1rem"}}>{t("(Maadi 11728- PO Box 437)")}</p>
              </div>
            </div>
            <div class="col-lg-3 d-flex py-lg-0 py-2 align-items-center justify-content-lg-center  reveal container fade-right ">
              <div class="p-2">
                <i class="fas fa-mobile-alt fa-3x"></i>
              </div>
              <div class="ms-lg-2 ms-4 ">
                <h5>{t("mobile")}</h5>
                {Lang == "en" && (
                  <div>
                      <p style={{fontSize:"1rem"}}>(002) 01221900517 -(002) 01221900518</p>
                <p style={{fontSize:"1rem"}}>(002) 01221900521- (002) 01279297117 </p>
                  </div>
              

                )}

{Lang == "ar" && (
  <div>
                <p style={{fontSize:"1rem"}}> 01221900517 (002)- 01221900518 (002)</p>
                <p style={{fontSize:"1rem"}}>01221900521 (002) -  01279297117(002) </p>
                  </div>
                )}

                {/* <p>(002) 01221900517 - 01221900518</p> */}
              </div>
            </div>
            <div class="col-lg-3 py-lg-0 py-2 d-flex align-items-center justify-content-lg-center reveal container fade-right">
              <div class="p-2 py-lg-0 py-2">
                <FaFax
                  className=""
                  style={{ color: "#58a8c5", fontSize: "2.7rem" }}
                />
              </div>
              <div class="ms-lg-2 ms-3">
                <h5>{t("phone")}</h5>
                {Lang == "ar" && (
                  <div>
                <p style={{fontSize:"1rem"}}> 23595607  (00202)- 23596135 (00202) </p>
                <p style={{fontSize:"1rem"}}>23596136 (00202) </p>
                </div>
                )}
                {Lang == "en" && (
  <div>
  <p style={{fontSize:"1rem"}}> (00202) 23595607  - (00202) 23596135  </p>
  <p style={{fontSize:"1rem"}}> (00202) 23596136</p>
  </div>
                )}
              </div>
            </div>

            <div class="col-lg-3 py-lg-0 py-2 d-flex align-items-center justify-content-lg-center reveal container fade-right">
              <div class="p-2">
                <i class="far fa-envelope fa-3x"></i>
              </div>
              <div class="ms-lg-2 ms-3">
                <h5>{t("Email")}</h5>
                <p style={{fontSize:"1rem"}}>info@horuseg.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="footer text-light ">
        <div class="container">
                <div className="d-flex align-items-center justify-content-md-center px-2">
               
                    <span
                      style={{
                        color:"white",
                        fontSize: "1.5rem",
                        textDecorationLine: "none !important",
                      }}
                      className="me-4"

                    >
   {t("Follow Us On")}        
                <a
                  href="https://www.facebook.com/profile.php?id=100057231888348"
                  style={{
                    fontSize: "38px",
                    color: "white",
                    textDecorationLine: "none !important",
                  }}
                  className="mx-3"
target="_blank"
                >
                    <FaFacebook
                      className=" "
                      style={{ color: "white", fontSize: "1.6rem" }}
                    />
                </a>

                <a
                  href="https://www.linkedin.com/in/horus-specialist-manpower-recruitment-5b13b9140/"
                  style={{
                    fontSize: "38px",
                    color: "white",
                    textDecorationLine: "none !important",
                  }}
                  className="mx-2"
                  target="_blank"

                >
                    <FaLinkedin
                      className=""
                      style={{ color: "white", fontSize: "1.6rem" }}
                    />
                </a>
               </span>
                </div>
          
          </div>
      </section>

      <div class="bottom py-2 text-light">
        <div class="container d-flex justify-content-center align-items-center ">
          <div className="py-3 d-flex align-items-center ">
            <p>
              {t("Copyright")} © {t("Horus")}  {t("Specialist Manpower")}

            </p>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
