import './App.css';
import Home from './components/Home/home';
import Contact from './components/contact'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';

function App() {

  return (
    <div className="App p-0 m-0">
      

      <Router>
      
        <Switch>
        <Route path="/" exact component={Home} />
        <Route  path="/home" exact component={Home}/>    
        <Route  path="/contact" exact component={Contact}/>    


        </Switch>
          </Router>


    </div>
  );
}

export default App;
