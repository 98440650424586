import { React } from "react";
import "./../App.css";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';
import {MdArrowBackIos} from 'react-icons/md';
import Header from './Header/header';

const Home = () => {

    const { t } = useTranslation();

  return (
<>
<Header></Header>  
      
      
      
      
        <section class=" d-flex align-items-center py-5  " style={{backgroundColor:"#212d37"}}>
        <div class="container conttt mt-5 ">
        <div class=" d-flex justify-content-center mt-5">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfR_tXugpVxBsFnSrEqhaSh3QKrP75Fm42gpK5qdjjT_M0-WA/viewform?embedded=true" width="640" height="1200" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
        </div>
      </section>
<div class="bottom py-2 text-light">
        <div class="container d-flex justify-content-center">
          <div className="">
          {t("Copyright")} © {t("Horus")}  {t("Specialist Manpower")}
            <br />


            
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
